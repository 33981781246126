export default [
  {
    //header: 'general.upravnik',
    title: 'general.upravnik',
    icon: 'HomeIcon',
    /*children: [
      {
        title: 'general.your_units',
        route: 'your-units'
      },
      /!*{
        title: 'general.divider',
        route: 'regular-billing'
      },*!/
      /!*{
        title: 'general.billing',
        route: 'card-traffic'
      },*!/
      {
        title: 'general.divider',
        route: 'card-traffic'
      },
      {
        title: 'general.open_items',
        route: 'open-items'
      },
      {
        title: 'general.funds',
        route: 'reserve-fund-funds'
      },
      {
        title: 'general.manager_documents',
        route: 'manager-documents'
      },
      {
        title: 'general.readings',
        route: 'readings'
      },
      {
        title: 'Dobavitelji',
        route: 'suppliers'
      },
      {
        title: 'general.bulletin_board',
        route: 'bulletin-board'
      },
      {
        title: 'general.notifications',
        route: 'notifications'
      },
      {
        title: 'general.agent_messages',
        route: 'agent_messages'
      },
      {
        title: 'general.your_manager',
        route: 'e-manager'
      },
      {
        title: 'general.meetings',
        route: 'meetings'
      }
    ],*/
    route: 'e-manager',
    //route: 'e-upravnik'
    requiresAuth: true,
    requiresEmailConfirmation: true
  },
  {
    header: 'general.portal',
    icon: 'HomeIcon',
    children: [
      {
        title: 'general.news',
        route: 'news-list'
      },
      {
        title: 'Storitve',
        route: 'service-list',
        requiresAuth: true,
        requiresEmailConfirmation: true

      },
      {
        title: 'Trgovina',
        route: 'web-shop-items-list',
        requiresAuth: true,
        requiresEmailConfirmation: true
      },
      {
        title: 'general.real_estates',
        href: 'https://nepremicnine.esoseska.si'
      },
      {
        title: 'general.TRZNICA',
        route: 'ad-list'
      }
    ],
    route: '/portal'
  },
  {
    title: 'general.kontakti',
    icon: 'HomeIcon',
    route: 'contacts'
  }
]
