<template>
    <li
        v-if="canViewHorizontalNavMenuHeaderGroup(item)"
        class="dropdown nav-item"
        :class="{
            'sidebar-group-active active open': checkPortalRoute(item),
            'show': isOpen ,
        }"
        @mouseenter="() => updateGroupOpen(true)"
        @mouseleave="() => updateGroupOpen(false)"
    >
        <b-link :disabled="!(requiresEmailConfirmation(item))" class="nav-link d-flex align-items-center" :class="[{ 'dropdown-toggle': requiresAuth(item) && requiresEmailConfirmation(item)}, {'disabled-nav-item': !(requiresEmailConfirmation(item))}]" :to="item.route">
            <feather-icon
                size="14"
                :icon="item.icon"
            />
            <span >{{ t(item.header) }}</span>
        </b-link>
        <ul class="dropdown-menu" id="drop" v-if="requiresAuth(item) && requiresEmailConfirmation(item)">
            <b-row class="d-flex justify-content-end" style="position: absolute!important; left: 0px ; width: 100vw!important; padding-right: 18rem!important; box-shadow: 2px 0px 3px 2px #f4f4f4!important; background-color: #ffffff">
                <component
                    :is="resolveHorizontalNavMenuItemComponent(child)"
                    v-for="child in getAllowedRoutes(item.children)"
                    :key="child.title"
                    :item="child"
                />
            </b-row>
        </ul>
    </li>
</template>

<script>
    import { BLink, BRow} from 'bootstrap-vue'
    import { resolveHorizontalNavMenuItemComponent } from '@core/layouts/utils'
    import { useUtils as useAclUtils } from '@core/libs/acl'
    import { useUtils as useI18nUtils } from '@core/libs/i18n'
    import useHorizontalNavMenuHeaderGroup from './useHorizontalNavMenuHeaderGroup'
    import mixinHorizontalNavMenuHeaderGroup from './mixinHorizontalNavMenuHeaderGroup'

    import HorizontalNavMenuGroup from '../horizontal-nav-menu-group/HorizontalNavMenuGroup.vue'
    import HorizontalNavMenuLink from '../horizontal-nav-menu-link/HorizontalNavMenuLink.vue'

    //import authService from '@/auth/authService'

    export default {
        components: {
            BLink,
            BRow,
            HorizontalNavMenuGroup,
            HorizontalNavMenuLink
        },
        mixins: [mixinHorizontalNavMenuHeaderGroup],
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed:{
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        },
        created() {
            this.isAuth = this.$store.getters['user/loggedIn']
        },
        data() {
            return {
                isAuth: false
            }
        },
        methods: {
            checkPortalRoute(item) {
                return item.route === this.$route.path || this.isActive
            },
            requiresAuth(item) {
                return (!item.requiresAuth || (item.requiresAuth && this.isAuth))
            },
            requiresEmailConfirmation(item) {
                return (!item.requiresEmailConfirmation || (item.requiresEmailConfirmation && this.emailConfirmed))
            },
            getAllowedRoutes(children) {
                return children.filter(c => this.requiresAuth(c))
            }
        },
        setup(props) {
            const {
                isActive,
                updateIsActive,
                isOpen,
                updateGroupOpen
            } = useHorizontalNavMenuHeaderGroup(props.item)

            const { t } = useI18nUtils()
            const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils()

            return {
                isOpen,
                isActive,
                updateGroupOpen,
                updateIsActive,
                resolveHorizontalNavMenuItemComponent,

                // ACL
                canViewHorizontalNavMenuHeaderGroup,

                // i18n
                t
            }
        }
    }
</script>

<style scoped>
    .disabled-nav-item{
        opacity: 40%;
    }

#drop {
  border-radius: 0 !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu {
  min-width: 100% !important;
  background-color: rgba(255, 255, 255, 1) !important;
  z-index: 1005 !important;
}
.dropup, .dropright, .dropdown, .dropleft {
  position: unset !important;
}
#drop .row li:last-child {
  margin-left: auto;
  padding-right: 0;
}
#drop .row {
  margin-left: 0;
  margin-right: 0;
}
</style>
