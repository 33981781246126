<template>
    <layout-horizontal style="font-family: 'Encode Sans' !important;">

        <router-view />

        <template #navbar="{ toggleVerticalMenuActive }">
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" style="min-height: 0px !important;" />
        </template>

    </layout-horizontal>
</template>

<script>
    import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'

    import Navbar from '../components/Navbar.vue'

    export default {
        components: {
            LayoutHorizontal,
            Navbar

        }
    }
</script>

<style>
.navbar.header-navbar{
    min-height: 0px !important;
}
</style>