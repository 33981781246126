<template>
    <div
        class="horizontal-layout "
        :class="[layoutClasses]"
        :data-col="isNavMenuHidden ? '1-column' : null"
        style="height:inherit;"
    >
       
        <!-- NAVBAR -->
        <b-navbar
            :style="{
                backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
                boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
            }"
            :toggleable="false"
            class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
            :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
        >
            <slot
                name="navbar"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
            >
                <app-navbar-horizontal-layout-brand />
                <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
            </slot>
        </b-navbar>
        <!--/ NAVBAR -->
        
        <!-- EMAIL CONFIRMATION -->
        <div class="d-block d-xl-none">
            <email-confirm-banner />
        </div>

        <div class="horizontal-menu-wrapper" style="width: 100%!important; padding-right: 50px;">
            <div
                v-if="!isNavMenuHidden"
                class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block"
                :class="[navbarMenuTypeClass]"
            >
                <div class="d-flex align-items-center" style="margin-left: 5rem!important;">
                    <router-link :to="{name: 'home'}">
                        <b-img :src="appLogoImage" :alt="appName"  height="40px"/>
                    </router-link>

                    <div class="ml-auto">
                        <horizontal-nav-menu/>
                    </div>
                    <search/>
                </div>
            </div>


            <div class="d-none d-xl-block">
                <email-confirm-banner />
            </div>

            <!-- Vertical Nav Menu -->
            <vertical-nav-menu
                :is-vertical-menu-active="isVerticalMenuActive"
                :toggle-vertical-menu-active="toggleVerticalMenuActive"
                class="d-block d-xl-none"
            >
                <template #header="slotProps">
                    <slot
                        name="vertical-menu-header"
                        v-bind="slotProps"
                    />
                </template>
            </vertical-nav-menu>
            <!-- /Vertical Nav Menu -->
        </div>

        <!-- Vertical Nav Menu Overlay -->
        <div
            class="sidenav-overlay"
            :class="overlayClasses"
            @click="isVerticalMenuActive = false"
        />
        <!-- /Vertical Nav Menu Overlay -->
      
        
        <!-- CONTENT -->
        <!-- CONTENT TYPE: Left -->
        <transition
            :name="routerTransition"
            mode="out-in"
        >
            <component
                :is="layoutContentRenderer"
                :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
            >
                <template
                    v-for="(index, name) in $scopedSlots"
                    v-slot:[name]="data"
                >
                    <slot
                        :name="name"
                        v-bind="data"
                    />
                </template>
            </component>
        </transition>
        <!--/ Content -->
        <!--/ CONTENT -->
      

        <!-- Footer -->
        <footer
            class="footer footer-light "
            :class="[footerTypeClass]"
        >
            <slot name="footer">
                <app-footer />
            </slot>
        </footer>

        <slot name="customizer" />
    </div>
</template>

<script>
    import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
    import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
    import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
    import AppFooter from '@core/layouts/components/AppFooter.vue'
    import useAppConfig from '@core/app-config/useAppConfig'
    import { BNavbar, BRow, BImg, BCol } from 'bootstrap-vue'
    import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

    import { onUnmounted } from '@vue/composition-api'

    import { $themeConfig } from '@themeConfig'

    // Content Renderer
    import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
    import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
    import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
    import useLayoutHorizontal from './useLayoutHorizontal'
    import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'
    import Search from '../../../views/Components/Search.vue'

    // Vertical Menu
    import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
    import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
    import mixinLayoutHorizontal from './mixinLayoutHorizontal'

    import EmailConfirmBanner from '@/views/Components/EmailConfirmBanner.vue'


    export default {
        components: {
            AppBreadcrumb,
            AppFooter,
            HorizontalNavMenu,
            Search,

            AppNavbarHorizontalLayout,
            AppNavbarHorizontalLayoutBrand,
            BNavbar,
            BRow,
            BImg,
            BCol,

            // Content Renderer
            LayoutContentRendererDefault,
            LayoutContentRendererLeft,
            LayoutContentRendererLeftDetached,

            // Vertical Menu
            VerticalNavMenu,

            EmailConfirmBanner
        },
        mixins: [mixinLayoutHorizontal],
        computed: {
            layoutContentRenderer() {
                const rendererType = this.$route.meta.contentRenderer
                if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
                if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
                return 'layout-content-renderer-default'
            }
        },
        setup() {
            const {
                skin,
                navbarType,
                footerType,
                routerTransition,
                isNavMenuHidden
            } = useAppConfig()

            // Vertical Menu
            const {
                isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler
            } = useVerticalLayout(navbarType, footerType)

            // Resize handler
            resizeHandler()
            window.addEventListener('resize', resizeHandler)
            onUnmounted(() => {
                window.removeEventListener('resize', resizeHandler)
            })

            const {
                navbarMenuTypeClass,
                layoutClasses,
                footerTypeClass
            } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

            // Scroll Listener
            const { scrolledTo } = useScrollListener()

            const { appName, appLogoImage } = $themeConfig.app

            return {
                // skin
                skin,

                // Layout
                layoutClasses,

                // Navbar
                navbarType,
                navbarMenuTypeClass,

                // Menu Hidden
                isNavMenuHidden,

                // Router Transition
                routerTransition,

                // Footer
                footerTypeClass,

                // Scroll Listeners
                scrolledTo,

                // Vertical Menu
                isVerticalMenuActive,
                toggleVerticalMenuActive,
                overlayClasses,

                appName,
                appLogoImage
            }
        }
    }
</script>

<style>
.horizontal-menu li.active > a {
  background: transparent !important;
  color: black !important;
  box-shadow: none !important;
  font-weight: 700 !important;
}
.horizontal-menu li > a {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}
.horizontal-menu li > a:hover {
  color: #bdd7f1 !important;
}
#drop li > a .feather, #main-menu-navigation  li > a .feather {
  display: none;
}
.horizontal-menu  li.active > a.dropdown-item {
  color: black !important;
  font-weight: 700 !important;
}

#main-menu-navigation li > a {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}
#main-menu-navigation li {

}
.horizontal-menu  li > a.dropdown-item:hover {
  color: #72a5d8 !important;
}
.horizontal-menu .header-navbar .navbar-container {
  border-radius: 0 !important;
}

.horizontal-menu .dropdown.nav-item .dropdown-menu {
  box-shadow: none !important;
  z-index: 1100 !important;
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 10000px !important;
  left: -5000px !important;
  position: absolute !important;
  background: white;
}

#drop .row{
    margin-left: 4986px !important;
    /* max-width: 1440px !important; */
}

.horizontal-menu .dropdown-menu::after{
    content: "";
    background: transparent !important;
    width: 100%;
    z-index: 11111;
    margin-left: 4986px !important;
    top: -15px;
    height: 25px;
    max-width: 1440px !important;
    position: absolute;
}

.horizontal-menu .dropdown-menu::before{
    display: block !important;
    content: "";
    background: transparent !important;
    width: 60%;
    z-index: 11111;
    margin-left: 5200px !important;
    top: -45px;
    height: 50px;
    max-width: 350px !important;
    position: absolute;
}

.horizontal-menu .dropdown-menu > div {
  padding: 0 0 0 0.2rem !important;
  width: 100%;
}
.horizontal-menu .dropdown-menu > div li {
  margin-right: 1.5rem;
}
.horizontal-menu .dropdown-menu > div li:last-child {
  margin-left: 0px !important;
}
.horizontal-menu .dropdown-menu > div li a.dropdown-item {
  padding: 1.5rem 1rem !important;
  font-weight: 400;
  font-size: 17px !important;
  line-height: 21px;
  font-family: 'Encode Sans' !important;
  display: block !important;
}

.horizontal-menu .nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
