<template>
    <b-card class="mx-2 confimation-email mt-4" v-bind:class=" [ { 'd-none': emailConfirmed}, {'d-none': (this.$route.name === 'confirm-register' || this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'reset-password' || this.$route.name === 'cancel-reset-password' || this.$route.name === 'forgoten-password') } ]">
        <div class="row" v-if="emailSend == false">
            <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                Potrdite email naslov!
            </div>
            <div class="col-12 text-center pt-1">
                Pozdravljeni, hvala za registracijo, vendar preden želite nadaljevati z uporabljanjem vseh funkcionalnosti morate aktivirati svoj račun.
            </div>
            <div class="col-12 text-center pt-1">
                To lahko storite s klikom na spodnji gumb.
            </div>
            <div class="col-12 text-center pt-1">
                <b-button @click="resendConfirmationEmail()" pill class="button-resend">
                    Pošlji potrditveni email
                    <b-spinner class="ml-1" small v-if="emailSending"></b-spinner>
                </b-button>
            </div>
        </div>

        <div class="row" v-if="emailSend == true">
            <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                Na email smo vam poslali navodila za potrditev!
            </div>
            <div class="col-12 text-center mt-1">
                Ko boste uspešno potrdili email, ponovno naložite stran.
            </div>
        </div>

    </b-card>
</template>

<script>
    import { BButton, BCard, BSpinner } from 'bootstrap-vue'
    export default {
        components:{
            BButton,
            BCard,
            BSpinner
        },
        computed:{
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        },
        data() {
            return {
                emailSend: false,
                emailSending: false
            }
        },
        methods: {
            async resendConfirmationEmail() {
                this.emailSending = true
                try {
                    await this.$http.get('/api/user/v1/send_confirmation_email')
                    this.emailSend = true
                    this.emailSending = false
                } catch (err) {
                    this.$printError('Prislo je do napake')
                    this.emailSending = false
                }
            }
        }
    }
</script>

<style scoped>
    .confimation-email{
        background: #eaf3f9 !important;
        box-shadow: none !important;
        border-radius: 10px !important;
    }

    .button-resend{
        box-shadow: none !important;
        background: #72a5d8 !important;
    }
    .button-resend:hover, .button-resend:active, .button-resend:focus{
        box-shadow: none !important;
        background: #72a5d8 !important;
    }
</style>